 <template>
  <b-card>
    <template #header>
      <strong>服務設定</strong>
    </template>
    <b-row>
      <b-col md="4">
        <b-card no-body header="項目">
          <template #header>
            <div class="text-center"><strong>服務項目</strong></div>
          </template>
          <b-list-group flush>
            <b-list-group-item action v-for="item in servicelist" :key="item.sort" @click="selectService(item.sort)" :active="selectedService === item.sort">
              <div class="d-flex justify-content-between">
                <span class="font-weight-bold" v-if="item.enable">{{ item.content }}</span>
                <span class="font-weight-light" v-else>{{ item.content }} <b-badge class="ml-1" pill variant="secondary">關閉</b-badge></span>
                <b-dropdown right variant="link" toggle-class="text-decoration-none p-0" no-caret v-if="selectedService === item.sort">
                  <template #button-content>
                    <font-awesome-icon class="text-white" icon="ellipsis-h" />
                  </template>
                  <b-dropdown-item @click.stop="upSort(item.sort)"> <font-awesome-icon fixed-width icon="caret-up" class="mr-3" />上移 </b-dropdown-item>
                  <b-dropdown-item @click.stop="downSort(item.sort)"> <font-awesome-icon fixed-width icon="caret-down" class="mr-3" />下移</b-dropdown-item>
                  <b-dropdown-item @click="editService(item.sort)"><font-awesome-icon fixed-width icon="edit" class="mr-3" />編輯</b-dropdown-item>
                  <b-dropdown-item @click="enableService(item.sort, false)" v-if="item.enable"><font-awesome-icon fixed-width icon="ban" class="mr-3" />關閉</b-dropdown-item>
                  <b-dropdown-item @click="enableService(item.sort, true)" v-else><font-awesome-icon fixed-width icon="play-circle" class="mr-3" />開啟</b-dropdown-item>
                  <b-dropdown-item @click="removeService(item.sort)"><font-awesome-icon fixed-width icon="trash" class="mr-3" />刪除</b-dropdown-item>
                </b-dropdown>
              </div>
            </b-list-group-item>
          </b-list-group>
          <b-button variant="link" @click="createService">增加服務項目</b-button>
        </b-card>
      </b-col>
      <b-col v-if="selectedService != null">
        <b-card v-for="item in itemlist" :key="item.sort">
          <b-row>
            <b-col cols="8">
              <b-form-group label="項目名稱" class="mb-0">
                <b-form-input v-model="item.title"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group label="項目名稱" class="mb-0">
                <b-form-select v-model.number="item.type" class="mb-3">
                  <b-form-select-option value="0">單選</b-form-select-option>
                  <b-form-select-option value="1">複選</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="操作" class="mb-0">
                <b-dropdown right variant="outline-primary" no-caret>
                  <template #button-content>
                    <font-awesome-icon icon="ellipsis-h" />
                  </template>
                  <b-dropdown-item @click.stop="upItemSort(item.sort)"> <font-awesome-icon fixed-width icon="caret-up" class="mr-3" />上移 </b-dropdown-item>
                  <b-dropdown-item @click.stop="downItemSort(item.sort)"> <font-awesome-icon fixed-width icon="caret-down" class="mr-3" />下移</b-dropdown-item>
                  <b-dropdown-item @click="removeItem(item.sort)"><font-awesome-icon fixed-width icon="trash" class="mr-3" />刪除</b-dropdown-item>
                </b-dropdown>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="選項內容" class="mb-0">
                <b-input-group>
                  <b-form-input v-model="createOption[item.sort].text" placeholder="輸入新選項並按新增" @keydown.enter="addOption(item.sort)" class="form-control"></b-form-input>
                  <b-input-group-append>
                    <b-button @click="addOption(item.sort)" variant="primary">新增</b-button>
                  </b-input-group-append>
                </b-input-group>
                <div class="d-inline-block mt-1">
                  <b-form-tag v-for="option in item.options" @remove="removeOption(item.sort, option.sort)" :key="option.sort" :title="option.content" class="mr-1">{{ option.content }}</b-form-tag>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-button variant="link" block @click="addItem()">增加項目內容</b-button>
      </b-col>
    </b-row>
    <template #footer>
      <b-button variant="primary" class="float-right" @click="update()"> 儲存修改 </b-button>
    </template>
    <b-modal v-model="create.show" title="新增服務項目">
      <b-form-group description="輸入欲新增的項目的內容" label="服務項目內容">
        <b-form-input v-model="create.text"></b-form-input>
      </b-form-group>

      <template #modal-footer>
        <b-button variant="primary" class="float-right" @click="createServiceAction()"> 確認新增 </b-button>
      </template>
    </b-modal>

    <b-modal v-model="edit.show" title="編輯服務項目">
      <b-form-group description="輸入欲編輯的項目的內容" label="服務項目內容">
        <b-form-input v-model="edit.text"></b-form-input>
      </b-form-group>

      <template #modal-footer>
        <b-button variant="primary" class="float-right" @click="editServiceAction()"> 確認編輯 </b-button>
      </template>
    </b-modal>
    <b-overlay :show="loading" spinner-variant="primary" spinner-type="grow" no-wrap> </b-overlay>
  </b-card>
</template>
<script>
export default {
  name: "ServiceSetting",
  data: () => ({
    loading: true,
    datalist: [],
    selectedService: null,
    create: {
      show: false,
      text: "",
    },
    edit: {
      show: false,
      text: "",
      sort: 0,
    },
    createOption: [],
  }),
  computed: {
    itemlist() {
      let service = this.datalist.find((p) => p.sort == this.selectedService);
      return service.items.sort((a, b) => a.sort - b.sort);
    },
    servicelist() {
      return this.datalist
        .map((p) => ({
          content: p.content,
          sort: p.sort,
          enable: p.enable,
        }))
        .sort((a, b) => a.sort - b.sort);
    },
  },
  mounted() {
    this.getServices();
  },
  methods: {
    upItemSort(sort) {
      if (sort === 0) return;
      let service = this.datalist.find((p) => p.sort == this.selectedService);
      let take = service.items.find((p) => p.sort === sort);
      let _up = service.items.find((p) => p.sort === sort - 1);
      take.sort = sort - 1;
      _up.sort = sort;
    },
    downItemSort(sort) {
      let service = this.datalist.find((p) => p.sort == this.selectedService);
      if (sort === Math.max(...service.items.map((p) => p.sort))) return;
      let take = service.items.find((p) => p.sort === sort);
      let _down = service.items.find((p) => p.sort === sort + 1);
      take.sort = sort + 1;
      _down.sort = sort;
    },
    removeItem(sort) {
      let service = this.datalist.find((p) => p.sort == this.selectedService);
      let item = service.items.findIndex((p) => p.sort === sort);
      service.items.splice(item, 1);
      this.createOption.splice(item, 1);
      service.items = this.renewSort(service.items);
      this.createOption = this.renewSort(this.createOption);
    },
    removeOption(itemSort, optionSort) {
      let service = this.datalist.find((p) => p.sort == this.selectedService);
      let item = service.items.find((p) => p.sort === itemSort);
      let option = item.options.findIndex((p) => p.sort === optionSort);
      item.options.splice(option, 1);
      item.options = this.renewSort(item.options);
    },
    addOption(sort) {
      let service = this.datalist.find((p) => p.sort == this.selectedService);
      let item = service.items.find((p) => p.sort === sort);
      if (this.createOption[sort].text === "") return;
      if (item.options.some((p) => p.content === this.createOption[sort].text)) return;
      let _sort = item.options.length > 0 ? Math.max(...item.options.map((p) => p.sort)) : -1;
      item.options.push({
        content: this.createOption[sort].text,
        sort: _sort + 1,
        enable: true,
      });
      item.options = item.options.sort((a, b) => a.sort - b.sort);
      this.createOption[sort].text = "";
    },
    addItem() {
      let service = this.datalist.find((p) => p.sort == this.selectedService);
      let sort = service.items.length > 0 ? Math.max(...service.items.map((p) => p.sort)) : -1;
      service.items.push({
        title: "",
        type: 0,
        sort: sort + 1,
        enable: true,
        options: [],
      });
      this.createOption.push({
        text: "",
        index: sort + 1,
      });
      this.createOption = this.createOption.sort((a, b) => a.sort - b.sort);
    },
    removeService(sort) {
      this.$bvModal
        .msgBoxConfirm("確認是否刪除此服務，此動作無法還原。", {
          title: "刪除服務項目",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "確認",
          cancelTitle: "取消",
          footerClass: "p-2",
          hideHeaderClose: true,
        })
        .then((value) => {
          if (value) {
            let index = this.datalist.findIndex((p) => p.sort == sort);
            this.datalist.splice(index, 1);
            this.datalist = this.renewSort(this.datalist);
          }
        });
    },
    enableService(sort, enable) {
      let service = this.datalist.find((p) => p.sort == sort);
      service.enable = enable;
    },
    upSort(sort) {
      if (sort === 0) return;
      let take = this.datalist.find((p) => p.sort === sort);
      let _up = this.datalist.find((p) => p.sort === sort - 1);
      take.sort = sort - 1;
      _up.sort = sort;
      this.selectService(take.sort);
    },
    downSort(sort) {
      if (sort === Math.max(...this.datalist.map((p) => p.sort))) return;
      let take = this.datalist.find((p) => p.sort === sort);
      let _down = this.datalist.find((p) => p.sort === sort + 1);
      take.sort = sort + 1;
      _down.sort = sort;
      this.selectService(take.sort);
    },

    createService() {
      this.create.show = true;
      this.create.text = "";
    },
    createServiceAction() {
      if (this.datalist.some((p) => p.content === this.create.text)) {
        this.$notify({ type: "error", text: "已經存在相同服務，請建立其他服務。" });
        return;
      }
      let sort = this.datalist.length > 0 ? Math.max(...this.datalist.map((p) => p.sort)) : -1;

      this.datalist.push({
        content: this.create.text,
        sort: sort + 1,
        enable: true,
        items: [],
      });
      this.create.show = false;
    },
    editService(sort) {
      this.edit.sort = sort;
      this.edit.text = this.datalist.find((p) => p.sort === sort).content;
      this.edit.show = true;
    },
    editServiceAction() {
      if (this.datalist.some((p) => p.content === this.edit.text)) {
        this.$notify({ type: "error", text: "已經存在相同服務，請修改為其他服務。" });
        return;
      }
      let data = this.datalist.find((p) => p.sort === this.edit.sort);
      data.content = this.edit.text;
      this.edit.show = false;
    },
    selectService(val) {
      let service = this.datalist.find((p) => p.sort == val);
      this.createOption = this.renewSort(service.items.map((p) => ({ text: p.content, index: 0 })));
      this.selectedService = val;
    },
    getServices() {
      this.$http.get("/service").then((response) => {
        if (response.data.success) {
          this.datalist = response.data.result;
        } else {
          this.$notify({ type: "error", text: response.data.message });
        }
        this.loading = false;
      });
    },
    update() {
      this.$http.post("/service", { services: this.datalist }).then((response) => {
        if (response.data.success) {
          this.$notify({ type: "success", text: "服務項目設定成功" });
        } else {
          this.$notify({ type: "error", text: response.data.message });
        }
      });
    },
    renewSort(array) {
      let result = array.sort((a, b) => a.sort - b.sort);
      result.forEach((item, index) => {
        item.sort = index;
      });
      return result;
    },
  },
};
</script>